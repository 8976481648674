import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Homepage from "./containers/Homepage";
import Playground from "./containers/Playground";


export default class Main extends React.Component {

    render () {
        return (
            <Switch>
                {/* Main */}
                <Route exact path={"/playground"} component={Playground}/>>
                {/* Default */}
                <Route exact path='*' component={Homepage}/>
            </Switch>
        );
    }
}