import React from 'react';
import Header from "../components/Header";
import Splash from "../components/Splash";
import Services from "../components/Services";
import About from "../components/About";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";

export default class Homepage extends React.Component {

    render () {
        return (
            <div className="Main">
                <Header/>
                <Splash/>
                <Services/>
                <About/>
                <ContactUs/>
                <Footer/>
            </div>
        );
    }
}