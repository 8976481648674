export default class APIUtility {

    static makeGetRequest(url, csrfToken) {
        return APIUtility.makeRequest(url, 'GET', csrfToken);
    }

    static makePostRequest(url, csrfToken, body) {
        return APIUtility.makeRequest(url, 'POST', csrfToken, body);
    }

    static makePutRequest(url, csrfToken, body) {
        return APIUtility.makeRequest(url, 'PUT', csrfToken, body);
    }

    static makeRequest(url, method, csrfToken, body = null) {
        let headers;
        if (csrfToken !== undefined && csrfToken.length > 0) {
            console.info('csrftoken: ' + csrfToken);
            headers = new Headers({'Content-Type': 'application/json', 'csrftoken': csrfToken});
        }
        else {
            console.info('no csrf token');
            headers = new Headers({'Content-Type': 'application/json', "x-api-key": "xT6aa52Lub5bDNORSsZR0aj6yQXaoWvT5uDiDyDd"});
        }

        console.log('INSIDE Create REQUEST: ' + url);

        let request;

        if (body !== null) {
            request = new Request(url,
                {
                    headers: headers,
                    method: method,
                    body: body,
                    mode: 'cors',
                    cache: 'default',
                    credentials: 'include'
                });
        } else {
            request = new Request(url,
                {
                    headers: headers,
                    method: method,
                    mode: 'cors',
                    cache: 'default',
                    credentials: 'include'
                });
        }

        console.log('About to Fetch this request: ' + request);
        let response='';
        try {
            response = fetch(request).then(
                function (response) {
                    console.info('Response Status: ' + response.status);
                    console.info(response);
                    return response;
                }
            );
        }
        catch (e) {
            console.error(e);
        }
        return response;
    }
}

export class RequestError extends Error {
    constructor(message, response) {
        super(message);
        this.status = response.status;
        this.body = response.body;
    }
}
