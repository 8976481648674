import React from 'react';
import PropTypes from "prop-types";
import './QuoteBox.scss';
import openQuote from "../../assets/open_quote.svg";
import closeQuote from "../../assets/close_quote.svg";

QuoteBox.propTypes = {
    quote: PropTypes.string,
    author: PropTypes.string,
    organization: PropTypes.string
};

function QuoteBox({quote, author, organization}) {
    return (
        <div className="QuoteBox">
            <div className="QuoteBox__OpenQuote"><img src={openQuote} alt="open quote" /></div>
            <div className="QuoteBox__Quote">{quote}</div>
            <div className="QuoteBox__CloseQuote"><img src={closeQuote} alt="close quote" /></div>
            <div className="QuoteBox__Author">- {author}</div>
            <div className="QuoteBox__Organization">{organization}</div>
        </div>
    );
}

export default QuoteBox;