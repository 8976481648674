import React from 'react';
import PropTypes from 'prop-types';
import './Tile.scss';

Tile.propTypes = {
    title: PropTypes.string,
    iconSource: PropTypes.string
};

function Tile({title, iconSource}) {
    return (
        <div className="Tile">
            <div className="Tile__icon"><img src={iconSource} alt={title} /></div>
            <div className="Tile__title">{title}</div>
        </div>
    );
}

export default Tile;
