import React from 'react';
import './Services.scss';
import Tile from "./Tile";
import search from "../../assets/search.svg";
import gears from "../../assets/gears.svg";
import sextant from "../../assets/sextent.svg";
import list from "../../assets/list.svg";
import mentoring from "../../assets/mentors.svg";


function Services() {
    return (
        <div className="Services">
            <h1 id="services">Services</h1>
            <div className="Services__Tiles">
                <Tile title="Project Management" iconSource={gears}/>
                <Tile title="Application Architecture" iconSource={sextant}/>
                <Tile title="Requirements Gathering" iconSource={list}/>
                <Tile title="Developer Mentoring" iconSource={mentoring}/>
                <Tile title="Internet Marketing" iconSource={search}/>
            </div>
            <div className="Services__Copy">
                Since 2005, Fidelis Designs has been helping companies and non-profits navigate the wild and ever changing landscape of corporate IT and software development. Our team of consultants and developers specialize in
                capturing exactly what is required for a project and architecting solutions that leverage the latest technology innovations. We help organizations realize that IT should not be relegated to a single department but
                should permeated every aspect of a company.

            </div>
        </div>
    );
}

export default Services;