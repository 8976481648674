import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <div className="Footer">
            <div>© Fidelis Designs, Inc. 2019</div>
        </div>
    );
}

export default Footer;