import React from 'react';
import './ContactUs.scss';

function ContactUs() {
    return (
        <div className="ContactUs">
            <h1 id="contactUs">Contact Us</h1>
            <div className="ContactUs__Details">
                <div className="ContactUs__Details__Copy">
                    For more information about our services and to have one of our consultants contact you about your project requirements, please email or call us.
                </div>
                <div className="ContactUs__Details__Info">
                    <div>Email: <a href="mailTo:info@fidelisdesigns.com">Info@fidelisdesigns.com</a></div>
                    <div>Phone: <a href="callTo:3135802773">313-580-2773</a></div>
                </div>

            </div>
        </div>
    );
}

export default ContactUs;