import React from 'react';
import './About.scss';
import QuoteBox from "./QuoteBox";
import frank from "../../assets/frank_medium.png";

function About() {
    return (
        <div className="About">
            <h1 id="about">About</h1>
            <div className="About__Box">
                <div className="About__Box__Personal">
                    <img src={frank} alt="Frank Law" />
                </div>
                <div className="About__Box__Info">
                    Frank Law is the founder and President of Fidelis Designs, Inc. He has spent 20 years in the enterprise software industry
                    holding positions in application development, user interface development, mobile application development, and technology research.
                    Frank has been at the forefront of the major technology shifts from client-server to web applications and now to cloud computing.
                    <br />
                    <br />
                    Frank has led multiple major, complex IT Projects from the RFP process through to final delivery. Frank is a huge proponent of
                    DevOps and Continuous Development, and has helped multiple teams move from waterfall and extended development cycles to quicker and
                    more predictable agile development. To read more about Frank, click <a href="https://www.linkedin.com/in/frank-fidelis-law-iv/">here</a>.
                </div>
            </div>

            <h2>Testimonials</h2>
            <div className="About__Quotes">
                <QuoteBox quote="Fidelis Designs' development expertise and process knowledge has been a huge benefit to us."
                    author="Mitch Lipon"
                    organization="CEO, Ignite XDS" />
                <QuoteBox quote="Frank Law is the consummate IT professional. It has been a pleasure to work with him for the last few years on an important development project. He asks timely questions, provides excellent guidance and never waivers from achieving the vision."
                    author="J.G. Ted Gillary"
                    organization="Executive Manager, The Detroit Athletic Club" />
                <QuoteBox quote="We have been with Fidelis Designs for over a decade, and they have been a fantastic partner."
                    author="William Crandall"
                    organization="Senior Consultant, Crawford & Winiarski" />
            </div>
        </div>
    );
}

export default About;