import React from 'react';
import Main from './js/Main';
import './App.scss';

function App() {
    return (
        < Main />
    );
}

export default App;
