import React from 'react';
import splash from "../../assets/splash.svg";
import "./Splash.scss";

export default function Splash() {
    return (
        <div className="Splash">
            <img src={splash} alt="Splash" />
        </div>
    );
}