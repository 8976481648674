import React from 'react';
import "./Header.scss";
import logo from "../../assets/fd_white_logo.svg";

class Header extends React.Component {

    render() {
        return(
            <div className="Header">
                <div className="Header__Logo"><a href="/"><img src={logo} alt="Fidelis Designs" /></a></div>
                <div className="Header__Navigation">
                    <ul>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#about">About</a></li>
                        <li><a href="#contactUs">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Header;