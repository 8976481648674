import React from 'react';
import PropTypes from 'prop-types';
import APIUtility from "../utils/APIUtility";

class Playground extends React.Component {

    static propTypes = {
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const url = "https://noq6q3a5ql.execute-api.us-east-2.amazonaws.com/beta/api";
        console.error(APIUtility.makePostRequest(url, undefined, {"body":"js works!"}));
    }

    render() {
        return(
            <div>
                <h1>Playground</h1>
                <button onClick={this.onClick}>Click Me!</button>
            </div>
        );
    }
}

export default Playground;